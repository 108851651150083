import { Link as NavLink } from "react-router-dom";
import {
  Box,
  BoxProps,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { SidebarLinkItem } from "./SidebarContent";

interface MobileMenuProps extends BoxProps {
  linkItems: SidebarLinkItem[];
}

export const MobileMenu: React.FC<MobileMenuProps> = ({
  linkItems,
  ...boxProps
}) => {
  return (
    <Box {...boxProps}>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Menu"
          icon={<Icon as={BiDotsHorizontalRounded} />}
          variant="ghost"
        />

        <MenuList>
          {linkItems.map((link) => (
            <Link key={link.name} as={NavLink} to={link.to}>
              <MenuItem>
                <Icon as={link.icon} marginRight={2} />
                <Text size="md">{link.name}</Text>
              </MenuItem>
            </Link>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};
