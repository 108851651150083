import { HStack, Icon, Text } from "@chakra-ui/react";
import { ButtonLink } from "./ButtonLink";
import { BiArrowBack } from "react-icons/bi";

interface BackButtonProps {
  to: string;
}

export const BackButton: React.FC<BackButtonProps> = ({ to }) => {
  return (
    <ButtonLink to={to} buttonProps={{ variant: "ghost" }}>
      <HStack>
        <Icon as={BiArrowBack} />
        <Text>Back</Text>
      </HStack>
    </ButtonLink>
  );
};
