import { isPast } from "date-fns";
import { VianikoEvent } from "../../../types/events";
import { Button, Heading, HStack, Icon } from "@chakra-ui/react";
import { BiShareAlt } from "react-icons/bi";
import { useCurrentUser } from "../../../providers/CurrentUserProvider";
import { useNavigate } from "react-router-dom";
import {
  authUrl,
  eventCheckinUrl,
  eventShowUrl,
} from "../../../services/routes/urlBuilder";
import { eventLevelTicketsUnvailable } from "../services";
import { useEventTickets } from "../../../hooks/useEventTickets";
import { ButtonLink } from "../../../components/ButtonLink";
import {
  hasPermission,
  PERMISSION_CHECKIN,
} from "../../../services/permissions";
import { useOrganizationUser } from "../../../providers/CurrentOrganizationUserProvider";

interface EventActionButtonProps {
  event: VianikoEvent;
  isAdmin: boolean;
  onInviteFriendsClick: () => void;
  onGetTicketsClick: () => void;
}

export const EventActionButton: React.FC<EventActionButtonProps> = ({
  event,
  isAdmin,
  onInviteFriendsClick,
  onGetTicketsClick,
}) => {
  const { currentUser } = useCurrentUser();
  const { organizationUser } = useOrganizationUser(event.organization_id);
  const { tickets } = useEventTickets(event.id);
  const navigate = useNavigate();

  const handleGetTickets = () => {
    if (!currentUser) {
      navigate(
        authUrl(eventShowUrl(event.id, { ticket_selection: true }), {
          eventId: event.id,
        })
      );
    } else if (!!event.parent_event_id) {
      navigate(eventShowUrl(event.parent_event_id, { ticket_selection: true }));
    }

    onGetTicketsClick();
  };

  const handleInviteFriends = () => {
    onInviteFriendsClick();
  };
  const hasCheckinPermission =
    isAdmin || hasPermission(organizationUser?.permissions, PERMISSION_CHECKIN);

  return (
    <>
      <HStack gap={1}>
        {hasCheckinPermission ? (
          <ButtonLink
            to={eventCheckinUrl(event.id)}
            buttonProps={{ size: "lg", variant: "primary" }}
          >
            Check in guests
          </ButtonLink>
        ) : tickets.length > 0 ? (
          <Button
            size="lg"
            variant="primary"
            isDisabled
            onClick={handleGetTickets}
          >
            You're going
          </Button>
        ) : null}

        {isPast(event.end_at) ? (
          <Button size="lg" variant="primary" isDisabled width="100%">
            This event is over
          </Button>
        ) : tickets.length > 0 || hasCheckinPermission ? (
          <Button
            size="lg"
            variant="primary"
            flexGrow={1}
            onClick={handleInviteFriends}
          >
            <Icon as={BiShareAlt} marginX={2} />
            <Heading as="h5" size="sm">
              Invite friends
            </Heading>
          </Button>
        ) : eventLevelTicketsUnvailable(event) ? (
          <Button size="lg" variant="primary" isDisabled width="100%">
            Sold out
          </Button>
        ) : (
          <Button
            onClick={handleGetTickets}
            variant="primary"
            size="lg"
            width="100%"
          >
            Get tickets
          </Button>
        )}
      </HStack>
    </>
  );
};
