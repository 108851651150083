import { useEffect, useState } from "react";
import {
  FieldError,
  RegisterOptions,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import {
  AutocompleteInput,
  AutocompleteOption,
} from "./forms/AutocompleteInput";
import { searchOrganizationUsers } from "../services/api/organizationUsers";
import { VianikoUser } from "../types/users";
import { Box } from "@chakra-ui/react";
import { useCurrentUser } from "../providers/CurrentUserProvider";

interface OrganizationUserSelectInputProps {
  name: string;
  register: UseFormRegister<any>;
  organizationId: string;
  label: string;
  error?: FieldError;
  registerOptions?: RegisterOptions;
  setValue?: UseFormSetValue<any>;
  defaultValue?: string;
  clearErrors?: UseFormClearErrors<any>;
  onSelect?: (value: string) => void;
  placeholder?: string;
}

export const OrganizationUserSelectInput: React.FC<
  OrganizationUserSelectInputProps
> = ({
  name,
  organizationId,
  register,
  error,
  registerOptions,
  setValue,
  defaultValue,
  clearErrors,
  onSelect,
  label,
  placeholder,
}) => {
  const [options, setOptions] = useState<AutocompleteOption[]>([]);
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    const fetch = async () => {
      if (!currentUser) return;

      const result: VianikoUser[] = await searchOrganizationUsers(
        organizationId,
        ""
      );

      setOptions(
        result.map((user) => ({ id: user.id, label: user.full_name }))
      );
    };

    fetch();
  }, [organizationId, currentUser]);

  const handleChange = async (e: any) => {
    const query = e.target.value;

    if (query) {
      const result: VianikoUser[] = await searchOrganizationUsers(
        organizationId,
        query
      );

      setOptions(
        result.map((user) => ({ id: user.id, label: user.full_name }))
      );
    }
  };

  return (
    <Box width="100%">
      {options && (
        <AutocompleteInput
          name={name}
          label={label}
          onKeyUp={handleChange}
          error={error}
          clearErrors={clearErrors}
          selectOptions={options}
          register={register}
          registerOptions={registerOptions}
          setValue={setValue}
          defaultValue={defaultValue}
          onSelect={onSelect}
          placeholder={placeholder}
        />
      )}
    </Box>
  );
};
