import { Control, FieldErrors, useController } from "react-hook-form";

import { Select } from "@chakra-ui/react";
import { VianikoEvent } from "../../../../types/events";
import { Organization } from "../../../../types/organization";
import { CURRENCIES } from "../../../../services/currencies";

interface PaymentConfigurationFormSectionProps {
  control: Control<VianikoEvent>;
  errors?: FieldErrors<VianikoEvent>;
  organization?: Organization;
}

export const PaymentConfigurationFormSection: React.FC<
  PaymentConfigurationFormSectionProps
> = ({ control, errors, organization }) => {
  const {
    field: {
      onChange: currencyCodeOnChange,
      value: currencyCodeValue,
      name: currencyCodeName,
      ref: currencyCodeRef,
    },
  } = useController({
    name: "currency_iso_code",
    defaultValue: "USD",
    control,
  });

  return (
    <>
      <Select
        variant="subtle"
        isInvalid={!!errors?.currency_iso_code}
        width="fit-content"
        name={currencyCodeName}
        ref={currencyCodeRef}
        onChange={currencyCodeOnChange}
        value={currencyCodeValue}
      >
        {CURRENCIES.map((currency) => (
          <option key={currency.code} value={currency.code}>
            {currency.code}
          </option>
        ))}
      </Select>
    </>
  );
};
