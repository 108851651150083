import { HStack, LinkBox, LinkOverlay, Text, VStack } from "@chakra-ui/react";
import { format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { useCurrentEventUser } from "../../../providers/CurrentEventUserProvider";
import { VianikoEvent } from "../../../types/events";
import { useOrganizationUser } from "../../../providers/CurrentOrganizationUserProvider";
import { Panel } from "../../../components/Panel";
import { ButtonLink } from "../../../components/ButtonLink";
import { eventNewUrl, eventShowUrl } from "../../../services/routes/urlBuilder";
import { TEXT_SECONDARY_COLOR } from "../../../services/theme/colors";

interface ProgramsTabProps {
  event: VianikoEvent;
  subEvents: VianikoEvent[];
}

export const ProgramsTab: React.FC<ProgramsTabProps> = ({
  event,
  subEvents,
}) => {
  const { currentEventUser } = useCurrentEventUser();
  const { organizationUser } = useOrganizationUser(event.organization_id);

  return (
    <Panel>
      {subEvents.length > 0 && (
        <VStack gap={2} marginBottom={2}>
          {subEvents.map((subEvent) => (
            <HStack key={subEvent.id} width="100%">
              <LinkBox>
                <LinkOverlay href={eventShowUrl(subEvent.id)}>
                  <Text size="md">{subEvent.name}</Text>
                </LinkOverlay>
                <Text size="sm" color={TEXT_SECONDARY_COLOR}>
                  {format(
                    toZonedTime(
                      new Date(subEvent.start_at),
                      event.iana_timezone
                    ),
                    "E LLL d, h:mm a"
                  )}
                  -
                  {format(
                    toZonedTime(new Date(subEvent.end_at), event.iana_timezone),
                    "h:mm a"
                  )}
                </Text>
              </LinkBox>
            </HStack>
          ))}
        </VStack>
      )}
      {(currentEventUser?.is_owner || organizationUser?.is_owner) && (
        <ButtonLink
          to={eventNewUrl({
            organizationId: event.organization_id,
            parentEventId: event.id,
          })}
          buttonProps={{ variant: "primary", size: "lg", width: "100%" }}
        >
          Add to program
        </ButtonLink>
      )}
    </Panel>
  );
};
