import { VianikoEvent } from "../../../types/events";

export const eventLevelTicketsUnvailable = (event: VianikoEvent): boolean => {
  if (
    event.num_tickets_available === 0 ||
    (event.num_tickets_available && event.num_tickets_available < 0)
  ) {
    return true;
  }
  return false;
};
