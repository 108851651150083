import { useOutletContext } from "react-router-dom";
import { EventShowOutletContext } from ".";
import { EventAttendees } from "../components/EventAttendees";

export const AttendeesTab = () => {
  const { showAttendees, event, attendees, numAttendees, handleRefundTickets } =
    useOutletContext<EventShowOutletContext>();

  return showAttendees ? (
    <EventAttendees
      event={event}
      attendees={attendees}
      numAttendees={numAttendees}
      onRefundTickets={handleRefundTickets}
    />
  ) : null;
};
