import linkifyStr from "linkify-string";

export const getHtmlFromString = (string: string): string => {
  return linkifyStr(string).replace(/\n/g, "<br />");
};

export function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  const r = result ? parseInt(result[1], 16) : 0;
  const g = result ? parseInt(result[2], 16) : 0;
  const b = result ? parseInt(result[3], 16) : 0;

  return { r, g, b };
}

export const hexToRgbWithOpacity = (hex: string, opacity: number) => {
  const rgb = hexToRgb(hex);

  return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})`;
};
