import { useOutletContext } from "react-router-dom";
import { EventShowOutletContext } from ".";
import { EventTickets } from "../components/EventTickets";

export const TicketsTab = () => {
  const { showTickets, event, organization, tickets, isAdmin, onBuyTickets } =
    useOutletContext<EventShowOutletContext>();

  return showTickets ? (
    <EventTickets
      event={event}
      organization={organization}
      tickets={tickets}
      isAdmin={isAdmin}
      onBuyTickets={onBuyTickets}
    />
  ) : null;
};
