import { Heading, VStack } from "@chakra-ui/react";
import { useFeaturedOrganizations } from "../../hooks/useFeaturedOrganizations";
import { OrganizationListThemeCard } from "./components/OrganizationListThemeCard";

export const FeaturedOrganizations = () => {
  const { organizations } = useFeaturedOrganizations();

  return organizations.length > 0 ? (
    <>
      <Heading size="md" as="h3" margin={4}>
        Featured feathers
      </Heading>

      <VStack gap={1}>
        {organizations.map((organization) => (
          <OrganizationListThemeCard
            key={organization.id}
            organization={organization}
          />
        ))}
      </VStack>
    </>
  ) : null;
};
