import React, { useEffect, useState } from "react";
import {
  Box,
  Circle,
  Icon,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  StyleProps,
} from "@chakra-ui/react";
import { BiDollarCircle } from "react-icons/bi";
import { TicketType } from "../../../../types/ticket_types";
import { Control, useController } from "react-hook-form";

interface SlidingPriceProps extends StyleProps {
  name: string;
  ticketType: TicketType;
  control: Control<any>;
  defaultValue?: number;
}

export const SlidingPrice: React.FC<SlidingPriceProps> = ({
  name,
  ticketType,
  control,
  defaultValue,
  ...styleProps
}) => {
  const [defaultValueInitialized, setDefaultValueInitialized] = useState(false);

  const {
    field: { onChange: handleChange, value },
  } = useController({
    name,
    control,
  });

  useEffect(() => {
    if (defaultValue && !defaultValueInitialized) {
      handleChange(defaultValue);
      setDefaultValueInitialized(true);
    }
  }, [defaultValue, defaultValueInitialized, handleChange]);

  return (
    <Box {...styleProps}>
      <Slider
        min={ticketType.min_price_in_cents || 0}
        max={ticketType.max_price_in_cents || 10000}
        step={100}
        onChange={handleChange}
        value={value}
        colorScheme="blackAlpha"
        aria-label="sliding-price"
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb boxSize={6} bgColor="gray.200">
          <Circle size={6} backgroundColor="white" border="2px solid black">
            <Icon as={BiDollarCircle} fontSize="18px" />
          </Circle>
        </SliderThumb>
      </Slider>
    </Box>
  );
};
