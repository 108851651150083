import {
  Circle,
  HStack,
  Icon,
  Image,
  Link,
  LinkBox,
  LinkOverlay,
  Spacer,
} from "@chakra-ui/react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import VianikoLogo from "../assets/logos/logo.svg";

import { useCurrentUser } from "../providers/CurrentUserProvider";
import { authUrl, homeUrl, userShowUrl } from "../services/routes/urlBuilder";
import { useFriendshipRequests } from "../pages/events/hooks/useFriendshipRequests";
import { BiUserPlus } from "react-icons/bi";
import { UserAvatar } from "./UserAvatar";
import { ButtonLink } from "./ButtonLink";

export const AppHeader: React.FC = () => {
  const { currentUser } = useCurrentUser();
  const location = useLocation();

  const { friendshipRequestIncomingByUserId } = useFriendshipRequests();
  const numFriendshipRequests = Object.keys(
    friendshipRequestIncomingByUserId || {}
  ).length;

  return (
    <HStack height="100%">
      <Link as={RouterLink} to={homeUrl()}>
        <Image src={VianikoLogo} height="46px" paddingTop={2} />
      </Link>
      <Spacer />

      {currentUser ? (
        <LinkBox display="flex">
          {numFriendshipRequests > 0 && (
            <Circle backgroundColor="red.500" size={6} marginX={1}>
              <Icon as={BiUserPlus} fontSize="16px" color="white" />
            </Circle>
          )}
          <LinkOverlay as={RouterLink} to={userShowUrl(currentUser.id)}>
            <UserAvatar size="xs" user={currentUser} />
          </LinkOverlay>
        </LinkBox>
      ) : (
        <>
          <ButtonLink
            to="https://info.feather.rsvp"
            buttonProps={{ variant: "ghost" }}
          >
            About
          </ButtonLink>
          {!location.pathname.startsWith("/auth") && (
            <ButtonLink
              to={authUrl(location.pathname)}
              buttonProps={{ variant: "primary" }}
            >
              Sign in
            </ButtonLink>
          )}
        </>
      )}
    </HStack>
  );
};
