import { useOutletContext } from "react-router-dom";
import { ProgramsTab } from "../components/ProgramsTab";
import { EventShowOutletContext } from ".";

export const Programs = () => {
  const { showProgram, event, subEvents } =
    useOutletContext<EventShowOutletContext>();

  return showProgram ? (
    <ProgramsTab event={event} subEvents={subEvents} />
  ) : null;
};
