import { Box, Container } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { AppHeader } from "../components/AppHeader";
import { BACKGROUND_GRADIENT } from "../services/theme/colors";

const headerHeight = "50px";

interface RootProps {
  containerSize?: "sm" | "md" | "lg" | "xl" | "2xl";
}

export const Root: React.FC<RootProps> = ({ containerSize = "sm" }) => {
  return (
    <Box height="100vh">
      <Box background={BACKGROUND_GRADIENT} height="auto" minHeight="100vh">
        <Box height={headerHeight} paddingX={5}>
          <AppHeader />
        </Box>
        <Box padding={1}>
          <Container
            maxW={`container.${containerSize}`}
            minH={`calc(100vh - ${headerHeight})`}
          >
            <Outlet />
          </Container>
        </Box>
      </Box>
    </Box>
  );
};
