import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import {
  FieldError,
  RegisterOptions,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import { inputDefaultThemeProps } from "../../services/theme/overrides/input";
import { BiMinus, BiPlus } from "react-icons/bi";

interface QuantityInputProps {
  name: string;
  max: number;
  label?: string;
  register: UseFormRegister<any>;
  error?: FieldError;
  options?: RegisterOptions;
  prevValue: number;
  setValue: UseFormSetValue<any>;
}

export const QuantityInput: React.FC<QuantityInputProps> = ({
  name,
  max,
  label,
  register,
  error,
  options,
  prevValue: prevValueProp,
  setValue,
}) => {
  const prevValue = Number(prevValueProp);

  return (
    <HStack>
      <FormControl isInvalid={!!error} width="100%" marginTop={0}>
        {label && (
          <FormLabel htmlFor={name} marginBottom={0}>
            <Text size="md">{label}</Text>
          </FormLabel>
        )}

        <InputGroup>
          <InputLeftElement marginLeft={1}>
            <Button
              variant="ghost"
              padding={0}
              onClick={() => {
                if (!isNaN(prevValue) && prevValue > 0) {
                  setValue(name, prevValue - 1);
                } else {
                  setValue(name, 0);
                }
              }}
            >
              <Icon as={BiMinus} />
            </Button>
          </InputLeftElement>

          <Input
            {...register(name, options)}
            {...inputDefaultThemeProps}
            defaultValue={0}
            width="124px"
            textAlign="center"
          />
          <InputRightElement marginRight={1}>
            <Button
              variant="ghost"
              padding={0}
              onClick={() => {
                if (max && prevValue >= max) return;

                if (!isNaN(prevValue)) {
                  setValue(name, prevValue + 1);
                } else {
                  setValue(name, 1);
                }
              }}
            >
              <Icon as={BiPlus} />
            </Button>
          </InputRightElement>
        </InputGroup>
        {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
      </FormControl>
    </HStack>
  );
};
