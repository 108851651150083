import { useCallback, useEffect, useState } from "react";
import { TicketType } from "../types/ticket_types";
import { fetchTicketType } from "../services/api/ticketTypes";
import { useCurrentUser } from "../providers/CurrentUserProvider";

interface UseTicketType {
  ticketType: TicketType | null;
  refetch: () => void;
}

export const useTicketType = (ticketTypeId?: string): UseTicketType => {
  const [ticketType, setTicketType] = useState<TicketType | null>(null);
  const { currentUser } = useCurrentUser();

  const fetch = useCallback(async () => {
    if (!ticketTypeId || !currentUser) return;

    const ticketType = await fetchTicketType(ticketTypeId);
    setTicketType(ticketType);
  }, [ticketTypeId, currentUser]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { ticketType, refetch: fetch };
};
