import { useCallback, useEffect, useState } from "react";
import { Ticket } from "../types/ticket";
import { fetchEventTickets } from "../services/api/eventUsers";
import { useCurrentUser } from "../providers/CurrentUserProvider";
import { TicketEvent } from "../types/ticket_events";
import { fetchTicketEvents } from "../services/api/events";

interface UseEventTickets {
  tickets: Ticket[];
  eventTickets: TicketEvent[];
  refetch: () => void;
}
export const useEventTickets = (eventId?: string): UseEventTickets => {
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [eventTickets, setEventTickets] = useState<TicketEvent[]>([]);
  const { currentUser } = useCurrentUser();

  const fetch = useCallback(async () => {
    if (!eventId || !currentUser) return;
    const tickets = await fetchEventTickets(eventId);
    setTickets(tickets);

    const eventTickets = await fetchTicketEvents(eventId);
    setEventTickets(eventTickets);
  }, [eventId, currentUser]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { tickets, eventTickets, refetch: fetch };
};
