import { Ticket } from "../../types/ticket";
import axiosInstance from "./axiosInstance";

export const patchTicket = async (
  ticketId: string,
  ticket: Partial<Ticket>
) => {
  const result = await axiosInstance.patch(`/tickets/${ticketId}`, ticket);
  return result.data;
};
