import { ThemeOverride, extendTheme } from "@chakra-ui/react";
import { inputTheme } from "./components/input";
import {
  TEXT_MAIN,
  TEXT_SM,
  TITLE_LG,
  TITLE_MD,
  TITLE_SM,
  TITLE_XL,
} from "./fonts";
import {
  ALERT_RED_COLOR,
  GHOST_HOVER_COLOR,
  PRIMARY_HOVER_COLOR,
  TEXT_PRIMARY_COLOR,
  TEXT_SECONDARY_COLOR,
  TEXT_TERTIARY_COLOR,
} from "./colors";
import { tabsTheme } from "./components/tabs";
import { menuTheme } from "./components/menu";
import { avatarTheme } from "./components/avatar";
import { modalTheme } from "./components/modal";
import { checkboxTheme } from "./components/checkbox";
import { numberInputTheme } from "./components/number";
import { selectTheme } from "./components/select";

export const BRAND_PRIMARY_COLOR = "#5F6BBA";

const themeObject: ThemeOverride = {
  colors: {
    brand: {
      50: "#F0F2F9",
      100: "#D3D7EC",
      200: "#B6BCE0",
      300: "#99A1D3",
      400: "#7C86C7",
      500: BRAND_PRIMARY_COLOR,
      600: "#4551A1",
      700: "#363F7D",
      800: "#2E366B",
      900: "#1F2447",
    },
    red: {
      500: ALERT_RED_COLOR,
    },
  },
  fonts: {
    heading: `"Space Grotesk", sans-serif`,
    body: `"Space Grotesk", sans-serif`,
  },
  components: {
    Avatar: avatarTheme,
    Badge: {
      baseStyle: {
        fontFamily: "sans-serif",
      },
    },
    Button: {
      baseStyle: {
        fontWeight: "bold",
        borderRadius: "24px",
        padding: "4px 12px 4px 12px",

        _focus: {
          borderStyle: "dashed",
        },
      },
      sizes: {
        lg: {
          ...TITLE_SM,
        },
        md: {},
        sm: {
          ...TEXT_SM,
        },
      },
      variants: {
        primary: {
          backgroundColor: "white",
          border: "2px solid black",
          _hover: {
            backgroundColor: PRIMARY_HOVER_COLOR,
          },
          _disabled: {
            opacity: "1 !important",
            color: TEXT_SECONDARY_COLOR,
            border: "none",
            _hover: {
              backgroundColor: "white !important",
            },
          },
        },
        outline: {
          backgroundColor: "none",
          border: "2px solid black !important",
          _hover: {
            backgroundColor: GHOST_HOVER_COLOR,
          },
          _active: {
            backgroundColor: GHOST_HOVER_COLOR,
          },
        },
        ghost: {
          backgroundColor: "none",
          _hover: {
            backgroundColor: GHOST_HOVER_COLOR,
          },
          _active: {
            backgroundColor: GHOST_HOVER_COLOR,
          },
        },
        overlay: {
          backgroundColor: TEXT_SECONDARY_COLOR,
          color: "white",
        },
      },
    },
    Checkbox: checkboxTheme,
    Container: {
      baseStyle: {
        padding: 0,
      },
    },
    FormLabel: {
      baseStyle: {
        color: TEXT_PRIMARY_COLOR,
      },
    },
    Heading: {
      sizes: {
        xl: TITLE_XL,
        lg: TITLE_LG,
        md: TITLE_MD,
        sm: TITLE_SM,
      },
    },
    Icon: {
      baseStyle: {
        fontSize: "24px",
      },
    },
    Input: inputTheme,
    Link: {
      baseStyle: {
        color: TEXT_PRIMARY_COLOR,
        _hover: {
          textDecoration: "none",
        },
      },
    },
    Menu: menuTheme,
    Modal: modalTheme,
    NumberInput: numberInputTheme,
    Select: selectTheme,
    Tabs: tabsTheme,
    Text: {
      baseStyle: {
        ...TEXT_MAIN,
        color: TEXT_PRIMARY_COLOR,
      },
      sizes: {
        sm: TEXT_SM,
      },
      variants: {
        secondary: {
          color: TEXT_SECONDARY_COLOR,
        },
        tertiary: {
          color: TEXT_TERTIARY_COLOR,
        },
      },
    },
  },
};

export const vianikoTheme = extendTheme(themeObject);
