export const PERMISSION_MANAGE_EVENTS = 1 << 0;
export const PERMISSION_MANAGE_EVENTS_TEXT =
  "Ability to create and edit events";

export const PERMISSION_MANAGE_FEATHER = 1 << 1;
export const PERMISSION_MANAGE_FEATHER_TEXT = "Ability to manage feather";

export const PERMISSION_CHECKIN = 1 << 2;
export const PERMISSION_CHECKIN_TEXT = "Ability to check in attendees";

export const hasPermission = (
  permissions: number | bigint | undefined,
  permission: number | bigint
) => {
  if (!permissions) return false;

  return (BigInt(permissions) & BigInt(permission)) === BigInt(permission); //return bigInt(permissions).and(bigInt(permission)).eq(bigInt(permission));
};

export interface Permission {
  value: number;
  label: string;
}

export const ALL_PERMISSIONS: Permission[] = [
  {
    value: PERMISSION_MANAGE_EVENTS,
    label: PERMISSION_MANAGE_EVENTS_TEXT,
  },
  {
    value: PERMISSION_MANAGE_FEATHER,
    label: PERMISSION_MANAGE_FEATHER_TEXT,
  },
  {
    value: PERMISSION_CHECKIN,
    label: PERMISSION_CHECKIN_TEXT,
  },
];
