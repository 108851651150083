import { useCallback, useEffect, useState } from "react";
import { VianikoEvent } from "../types/events";
import { fetchAllRelatedEvents } from "../services/api/events";

interface UseAllRelatedEvents {
  events: VianikoEvent[];
  refetch: () => void;
}

export const useAllRelatedEvents = (
  eventId: string | undefined | null
): UseAllRelatedEvents => {
  const [events, setEvents] = useState<VianikoEvent[]>([]);

  const fetch = useCallback(async () => {
    if (!eventId) return;
    const events = await fetchAllRelatedEvents(eventId);
    setEvents(events);
  }, [eventId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { events, refetch: fetch };
};
