import { useCallback, useEffect, useState } from "react";
import { Organization } from "../types/organization";
import { fetchFeaturedOrganizations } from "../services/api/organizations";

export interface UseFeaturedOrganizations {
  organizations: Organization[];
}

export const useFeaturedOrganizations = (): UseFeaturedOrganizations => {
  const [organizations, setOrganizations] = useState<Organization[]>([]);

  const fetch = useCallback(async () => {
    const organizations = await fetchFeaturedOrganizations();
    setOrganizations(organizations);
  }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { organizations };
};
