import { useForm } from "react-hook-form";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Heading,
  HStack,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { TicketType } from "../../../../types/ticket_types";
import { StyledRegisteredInput } from "../../../../components/forms/StyledRegisteredInput";
import { DollarsInput } from "../../../../components/forms/DollarsInput";
import { StyledTextarea } from "../../../../components/forms/StyledTextarea";
import {
  createTicketType,
  updateTicketType,
} from "../../../../services/api/ticketTypes";
import { Panel } from "../../../../components/Panel";
import { useSubEvents } from "../../hooks/useSubEvents";
import { TicketTypePaymentType } from "./TicketTypePaymentType";
import { useEvent } from "../../../../hooks/useEvent";

interface TicketTypeFormProps {
  eventId: string;
  ticketType?: TicketTypeFormValues | null;
  onSubmit?: (data: TicketType) => void;
}

type TicketTypeFormValues = TicketType & {
  auto_enroll_event_ids?: string[];
};

export const TicketTypeForm: React.FC<TicketTypeFormProps> = ({
  eventId,
  ticketType,
  onSubmit: onSubmitProp,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
    control,
  } = useForm<TicketTypeFormValues>({
    values:
      ticketType ??
      ({
        id: "",
        name: "",
      } as TicketTypeFormValues),
    mode: "onBlur",
  });

  const paymentType = watch("payment_type");
  const hidden = watch("hidden");

  const { event } = useEvent(eventId);
  const { subEvents } = useSubEvents(eventId);

  const onSubmit = () => {
    handleSubmit(async (data: TicketType) => {
      let ticketType;
      if (data.id) {
        ticketType = await updateTicketType(data);
      } else {
        ticketType = await createTicketType(data);
      }

      if (onSubmitProp) {
        onSubmitProp(ticketType);
      }
    })();
  };

  return (
    <form>
      <Panel>
        <StyledRegisteredInput
          type="hidden"
          name="event_id"
          register={register}
          inputProps={{ value: eventId }}
        />

        <VStack gap={4}>
          <StyledRegisteredInput
            name="name"
            register={register}
            label="Name"
            options={{
              required: {
                value: true,
                message: "Enter a name for this ticket",
              },
            }}
            inputProps={{ placeholder: "General Admission" }}
            error={errors.name}
          />
          <StyledRegisteredInput
            name="capacity"
            type="number"
            register={register}
            label="Capacity"
          />

          <StyledTextarea
            name="description"
            register={register}
            label="Ticket details"
            error={errors.description}
            options={{
              maxLength: {
                value: 255,
                message: "Description must be less than 255 characters",
              },
            }}
          />

          <TicketTypePaymentType
            control={control}
            errors={errors}
            organizationId={event?.organization_id}
          />

          {paymentType === "fixed" ? (
            <DollarsInput
              name="price_in_cents"
              label="Price"
              defaultValue={1000}
              control={control}
            />
          ) : paymentType === "slider" ? (
            <VStack gap={4} marginTop={4} width="100%">
              <DollarsInput
                name="min_price_in_cents"
                label="Minimum price"
                defaultValue={1000}
                control={control}
              />
              <DollarsInput
                name="max_price_in_cents"
                label="Maximum price"
                defaultValue={2000}
                control={control}
              />
              <DollarsInput
                name="price_in_cents"
                label="Default price"
                defaultValue={1500}
                control={control}
              />
            </VStack>
          ) : null}

          <HStack width="100%">
            <Checkbox {...register("hidden")} />
            <Text size="sm">Visible only with a code</Text>
            <Spacer />
          </HStack>

          {hidden && (
            <StyledRegisteredInput
              type="text"
              name="hidden_ticket_code"
              register={register}
              options={{ required: { value: true, message: "Enter a code" } }}
              error={errors.hidden_ticket_code}
              label="Code"
              inputProps={{
                placeholder: "VIP20",
              }}
            />
          )}
        </VStack>
      </Panel>

      {subEvents.length > 0 && (
        <Panel>
          <Heading as="h2" size="sm" marginBottom={2}>
            Ticket signs up user for
          </Heading>

          <CheckboxGroup defaultValue={ticketType?.auto_enroll_event_ids}>
            <VStack width="100%">
              {subEvents.map((event) => (
                <HStack key={event.id} width="100%">
                  <Checkbox
                    value={event.id}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setValue("auto_enroll_event_ids", [
                          ...(getValues("auto_enroll_event_ids") || []),
                          event.id,
                        ]);
                      } else {
                        setValue(
                          "auto_enroll_event_ids",
                          (getValues("auto_enroll_event_ids") || []).filter(
                            (id) => id !== event.id
                          )
                        );
                      }
                    }}
                  />
                  <Text size="sm">{event.name}</Text>
                  <Spacer />
                </HStack>
              ))}
            </VStack>
          </CheckboxGroup>
        </Panel>
      )}

      <Button
        variant="primary"
        width="100%"
        marginTop={6}
        marginBottom={12}
        onClick={onSubmit}
      >
        Save
      </Button>
    </form>
  );
};
