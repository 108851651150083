import { useCallback, useEffect, useState } from "react";
import { EventUser } from "../../../types/event_users";
import { VianikoUser } from "../../../types/users";
import { fetchEventAttendees } from "../../../services/api/events";
import { useCurrentUser } from "../../../providers/CurrentUserProvider";

export type ExtendedUser = EventUser &
  VianikoUser & {
    event_user_id: string;
  };

interface UseAttendeesReturn {
  attendees: ExtendedUser[] | undefined;
  numAttendees: number;
  attendeesByUserId: Record<string, ExtendedUser> | undefined;
  refetch: () => void;
}

export const useAttendees = (id: string | undefined): UseAttendeesReturn => {
  const [attendees, setAttendees] = useState<ExtendedUser[]>();
  const [numAttendees, setNumAttendees] = useState<number>(0);
  const [attendeesByUserId, setAttendeesByUserId] =
    useState<Record<string, ExtendedUser>>();
  const { currentUser } = useCurrentUser();

  const fetch = useCallback(async () => {
    if (!id || !currentUser) return;

    const { attendees, numAttendees } = await fetchEventAttendees(id);
    const attendeesByUserId = attendees.reduce(
      (acc: any, attendee: ExtendedUser) => {
        acc[attendee.id] = attendee;
        return acc;
      },
      {}
    );
    setAttendees(attendees);
    setAttendeesByUserId(attendeesByUserId);
    setNumAttendees(numAttendees);
  }, [id, currentUser]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { attendees, attendeesByUserId, refetch: fetch, numAttendees };
};
