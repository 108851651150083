import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";
import { authUrl } from "../services/routes/urlBuilder";
import { useEffect } from "react";

export const AuthenticatedRoutes: React.FC = () => {
  const { user, refreshing, initialized } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (initialized && !user && !refreshing) {
      navigate(authUrl(pathname));
    }
  }, [initialized, user, refreshing, pathname, navigate]);

  return <Outlet />;
};
