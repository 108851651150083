import { selectAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(selectAnatomy.keys);

const baseStyle = definePartsStyle({
  baseStyle: {
    backgroundColor: "red.500",
  },
  field: {
    backgroundColor: "white",
    borderRadius: "24px",
  },
});

const subtle = definePartsStyle({
  field: {
    width: "fit-content",
    backgroundColor: "transparent",
  },
});

export const selectTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { subtle },
});
