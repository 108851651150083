export const homeUrl = () => "/";

interface AuthOptions {
  eventId?: string;
  organizationId?: string;
}

export const authUrl = (redirectTo?: string, options?: AuthOptions) => {
  const { eventId, organizationId } = options || {};
  const urlParams = new URLSearchParams({
    eventId: eventId || "",
    organizationId: organizationId || "",
    redirect: redirectTo ? encodeURIComponent(redirectTo) : "",
  });

  return `/auth?${urlParams.toString()}`;
};

interface UserShowUrlParams {
  tab: "events";
  eventsDirection: "future";
}
export const userShowUrl = (userId: string, params?: UserShowUrlParams) => {
  const { tab, eventsDirection } = params || {};
  const urlParams = params
    ? new URLSearchParams({
        tab: tab ? tab.toString() : "",
        eventsDirection: eventsDirection ? eventsDirection.toString() : "",
      })
    : "";

  return `/users/${userId}?${urlParams.toString()}`;
};

export const userEditUrl = (userId: string, redirectAfter?: string) =>
  `/users/${userId}/edit${
    redirectAfter ? `?redirectAfter=${encodeURIComponent(redirectAfter)}` : ""
  }`;

export const organizationShowUrl = (organizationId: string) =>
  `/organizations/${organizationId}`;
export const organizationEditUrl = (organizationId: string) =>
  `/organizations/${organizationId}/edit`;

interface EventShowUrlParams {
  invite?: boolean;
  welcome?: boolean;
  ticket_selection?: boolean;
}

export const eventShowUrl = (eventId: string, params?: EventShowUrlParams) => {
  const { invite, welcome, ticket_selection } = params || {};
  const urlParams = params
    ? new URLSearchParams({
        invite: invite ? invite.toString() : "",
        welcome: welcome ? welcome.toString() : "",
        ticket_selection: ticket_selection ? ticket_selection.toString() : "",
      })
    : "";

  return `/events/${eventId}?${urlParams.toString()}`;
};

export const eventAttendeesUrl = (eventId: string) =>
  `/events/${eventId}/attendees`;
export const eventProgramUrl = (eventId: string) =>
  `/events/${eventId}/program`;
export const eventTicketsUrl = (eventId: string) =>
  `/events/${eventId}/tickets`;
export const eventMessagesUrl = (eventId: string) =>
  `/events/${eventId}/messages`;

export const eventEditUrl = (eventId: string) => `/events/${eventId}/edit`;
interface EventNewUrlParams {
  organizationId?: string;
  parentEventId?: string;
}
export const eventNewUrl = (params?: EventNewUrlParams) => {
  const { organizationId, parentEventId } = params || {};

  const urlParams = new URLSearchParams({
    parentEventId: parentEventId || "",
  });

  return `${
    organizationId ? `/organizations/${organizationId}` : ""
  }/events/new?${urlParams.toString()}`;
};

export const ticketTypeNewUrl = (eventId: string) => {
  return `/events/${eventId}/ticket_types/new`;
};

export const ticketTypeEditUrl = (eventId: string, ticketTypeId: string) => {
  return `/events/${eventId}/ticket_types/${ticketTypeId}/edit`;
};

interface EventPaymentUrlParams {
  amountInCents?: string;
  paymentToken?: string;
}
export const eventPaymentUrl = (
  eventId: string,
  params?: EventPaymentUrlParams
) =>
  `/events/${eventId}/payments/new${
    params
      ? `?amountInCents=${params.amountInCents}&paymentToken=${params.paymentToken}`
      : ""
  }`;

interface PaymentsRedirectorOptions {
  amountInCents?: string;
  paymentToken?: string;
}
export const paymentsRedirectorUrl = (
  eventId: string,
  options?: PaymentsRedirectorOptions
) =>
  `/events/${eventId}/payments?${
    options
      ? new URLSearchParams({
          ...options,
        }).toString()
      : ""
  }`;

export const eventCheckinUrl = (eventId: string) =>
  `/events/${eventId}/checkin`;
export const eventScanUrl = (eventId: string) =>
  `/events/${eventId}/checkin/scan`;

export const recurringEventShowUrl = (recurringEventId: string) =>
  `/recurring_events/${recurringEventId}`;
export const recurringEventEditUrl = (recurringEventId: string) =>
  `/recurring_events/${recurringEventId}/edit`;
export const recurringEventNewUrl = (organizationId: string) =>
  `/organizations/${organizationId}/recurring_events/new`;

export const waiverNewUrl = (organizationId: string) =>
  `/organizations/${organizationId}/waivers/new`;
export const waiverEditUrl = (organizationId: string, waiverId: string) =>
  `/organizations/${organizationId}/waivers/${waiverId}/edit`;
export const waiverShowUrl = (
  organizationId: string,
  waiverId: string,
  redirectTo?: string
) =>
  `/organizations/${organizationId}/waivers/${waiverId}${
    redirectTo ? `?redirectTo=${encodeURIComponent(redirectTo)}` : ""
  }`;

export const adminManageOrganizationUsersUrl = (organizationId: string) =>
  `/admin/organizations/${organizationId}/manage_users`;
export const adminManageOrganizationPayoutUrl = (organizationId: string) =>
  `/admin/organizations/${organizationId}/manage-payout`;
