import {
  Box,
  Divider,
  Heading,
  HStack,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Panel } from "../../../../components/Panel";
import { priceForTicketType } from "../../../../services/payments";
import { TicketType } from "../../../../types/ticket_types";
import { VianikoEvent } from "../../../../types/events";
import { TEXT_SECONDARY_COLOR } from "../../../../services/theme/colors";
import { QuantityInput } from "../../../../components/forms/QuantityInput";
import { SlidingPrice } from "../pricing/SlidingPrice";

interface TicketTypeSelectionPanelProps {
  ticketType: TicketType;
  event: VianikoEvent;
  register: any;
  errors: any;
  setValue: any;
  watch: any;
  control: any;
}
export const TicketTypeSelectionPanel: React.FC<
  TicketTypeSelectionPanelProps
> = ({ ticketType, event, register, errors, setValue, watch, control }) => {
  return (
    <Panel key={ticketType.id}>
      <HStack>
        <Box>
          <Heading size="sm" as="h4">
            {ticketType.name}
          </Heading>
          <Text size="md" color={TEXT_SECONDARY_COLOR}>
            {priceForTicketType(
              ticketType.payment_type,
              ticketType.payment_type === "slider"
                ? watch(ticketType.id)?.amount_in_cents ?? 0
                : ticketType.price_in_cents ?? 0,
              event.currency_iso_code
            )}
          </Text>
        </Box>
        <Spacer />
        {ticketType.capacity === null ||
        (ticketType.num_tickets_available &&
          ticketType.num_tickets_available > 0) ? (
          <VStack>
            <QuantityInput
              name={`${ticketType.id}.quantity`}
              max={
                Math.min(
                  ...([
                    ticketType.num_tickets_available,
                    event.num_tickets_available,
                  ].filter((n) => !!n) as number[])
                ) || 99
              }
              register={register}
              error={errors[ticketType.id]?.quantity}
              prevValue={watch(ticketType.id)?.quantity}
              setValue={setValue}
            />
          </VStack>
        ) : (
          <Text size="md" color={TEXT_SECONDARY_COLOR}>
            Sold out
          </Text>
        )}
      </HStack>

      <HStack>
        {ticketType.payment_type === "slider" && (
          <SlidingPrice
            name={`${ticketType.id}.amount_in_cents`}
            ticketType={ticketType}
            defaultValue={ticketType.price_in_cents}
            control={control}
            width="100%"
            marginTop={2}
          />
        )}
      </HStack>

      {ticketType.description && (
        <>
          <Divider borderColor="gray.300" marginY={4} />

          <Text size="sm" marginTop={4}>
            {ticketType.description}
          </Text>
        </>
      )}
    </Panel>
  );
};
