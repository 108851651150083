import { useCallback, useEffect, useState } from "react";
import { fetchWaivers } from "../services/api/waivers";
import { Waiver } from "../types/waiver";
import { useCurrentUser } from "../providers/CurrentUserProvider";

interface UseWaivers {
  waivers: Waiver[] | undefined;
  refetch: () => void;
}

export const useWaivers = (organizationId: string | undefined): UseWaivers => {
  const [waivers, setWaivers] = useState<Waiver[]>();
  const { currentUser } = useCurrentUser();

  const fetch = useCallback(async () => {
    if (!organizationId || !currentUser) return;

    const result = await fetchWaivers(organizationId);
    setWaivers(result);
  }, [organizationId, currentUser]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { waivers, refetch: fetch };
};
