import { useCallback, useEffect, useState } from "react";
import { VianikoUser } from "../types/users";
import { fetchAllTicketsAdmin } from "../services/api/events";
import { useCurrentUser } from "../providers/CurrentUserProvider";
import { TicketStatus } from "../types/ticket";

export interface TicketsCheckin extends VianikoUser {
  ticket_id: string;
  status: TicketStatus;
  photo_id: string;
  ticket_type_name: string;
}

interface UseTicketsCheckin {
  checkinByTicketId: Record<string, TicketsCheckin>;
  updateStatus: (ticketId: string, status: TicketStatus) => void;
  refetch: () => void;
}

export const useTicketsCheckin = (
  eventId: string | undefined
): UseTicketsCheckin => {
  const [checkinByTicketId, setCheckinByTicketId] = useState<
    Record<string, TicketsCheckin>
  >({});
  const { currentUser } = useCurrentUser();

  const fetch = useCallback(async () => {
    if (!eventId || !currentUser) return;

    const ticketsCheckin = await fetchAllTicketsAdmin(eventId);

    setCheckinByTicketId(
      ticketsCheckin.reduce((acc: any, ticket: any) => {
        acc[ticket.ticket_id] = ticket;
        return acc;
      }, {} as Record<string, TicketsCheckin>)
    );
  }, [eventId, currentUser]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const updateStatus = useCallback((ticketId: string, status: TicketStatus) => {
    setCheckinByTicketId((prev) => ({
      ...prev,
      [ticketId]: { ...prev[ticketId], status },
    }));
  }, []);

  return { checkinByTicketId, refetch: fetch, updateStatus };
};
