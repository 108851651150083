import { useEffect, useState } from "react";
import QRCode from "qrcode";
import { Flex, Image } from "@chakra-ui/react";

interface QrCodeProps {
  value: string;
}

export const QrCode: React.FC<QrCodeProps> = ({ value }) => {
  const [dataUrl, setDataUrl] = useState<string>("");

  const generateQR = async (text: string) => {
    try {
      const dataUrl = await QRCode.toDataURL(text, {
        width: 500,
      });
      setDataUrl(dataUrl);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    generateQR(value);
  }, [value]);

  return (
    <Flex width="100%" marginY={2} justify="center">
      <Image width="100%" maxWidth="500px" src={dataUrl} />
    </Flex>
  );
};
