interface TimezoneOption {
  iana_timezone: string;
  display_name: string;
  abbreviation: string;
}

export const timezoneOptions: TimezoneOption[] = [
  {
    iana_timezone: "America/Detroit",
    display_name: "America/Detroit",
    abbreviation: "EDT",
  },
  {
    iana_timezone: "America/Havana",
    display_name: "America/Havana",
    abbreviation: "CDT",
  },
  {
    iana_timezone: "America/Louisville",
    display_name: "America/Louisville",
    abbreviation: "EDT",
  },
  {
    iana_timezone: "America/Montreal",
    display_name: "America/Montreal",
    abbreviation: "EDT",
  },
  {
    iana_timezone: "America/Nassau",
    display_name: "America/Nassau",
    abbreviation: "EDT",
  },
  {
    iana_timezone: "America/Toronto",
    display_name: "America/Toronto",
    abbreviation: "EDT",
  },
  {
    iana_timezone: "America/Vancouver",
    display_name: "America/Vancouver",
    abbreviation: "PDT",
  },
  {
    iana_timezone: "America/Winnipeg",
    display_name: "America/Winnipeg",
    abbreviation: "CDT",
  },
  {
    iana_timezone: "Canada/Central",
    display_name: "Canada/Central",
    abbreviation: "CDT",
  },
  {
    iana_timezone: "Canada/Eastern",
    display_name: "Canada/Eastern",
    abbreviation: "EDT",
  },
  {
    iana_timezone: "Canada/Pacific",
    display_name: "Canada/Pacific",
    abbreviation: "PDT",
  },
  {
    iana_timezone: "US/Central",
    display_name: "US/Central",
    abbreviation: "CDT",
  },
  {
    iana_timezone: "US/Eastern",
    display_name: "US/Eastern",
    abbreviation: "EDT",
  },
  {
    iana_timezone: "US/Michigan",
    display_name: "US/Michigan",
    abbreviation: "EDT",
  },
  {
    iana_timezone: "US/Pacific",
    display_name: "US/Pacific",
    abbreviation: "PDT",
  },
  {
    iana_timezone: "Pacific/Honolulu",
    display_name: "(UTC-10:00) Hawaii",
    abbreviation: "HST",
  },
  {
    iana_timezone: "America/Anchorage",
    display_name: "(UTC-09:00) Alaska",
    abbreviation: "AKDT",
  },
  {
    iana_timezone: "America/Los_Angeles",
    display_name: "(UTC-08:00) Pacific Time (US and Canada)",
    abbreviation: "PDT",
  },
  {
    iana_timezone: "America/Tijuana",
    display_name: "(UTC-08:00)Baja California",
    abbreviation: "PDT",
  },
  {
    iana_timezone: "America/Denver",
    display_name: "(UTC-07:00) Mountain Time (US and Canada)",
    abbreviation: "MDT",
  },
  {
    iana_timezone: "America/Mazatlan",
    display_name: "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
    abbreviation: "MST",
  },
  {
    iana_timezone: "America/Phoenix",
    display_name: "(UTC-07:00) Arizona",
    abbreviation: "MST",
  },
  {
    iana_timezone: "America/Regina",
    display_name: "(UTC-06:00) Saskatchewan",
    abbreviation: "CST",
  },
  {
    iana_timezone: "America/Guatemala",
    display_name: "(UTC-06:00) Central America",
    abbreviation: "CST",
  },
  {
    iana_timezone: "America/Chicago",
    display_name: "(UTC-06:00) Central Time (US and Canada)",
    abbreviation: "CDT",
  },
  {
    iana_timezone: "America/Mexico_City",
    display_name: "((UTC-06:00) Guadalajara, Mexico City, Monterrey",
    abbreviation: "CST",
  },
  {
    iana_timezone: "America/New_York",
    display_name: "(UTC-05:00) Eastern Time (US and Canada)",
    abbreviation: "EDT",
  },
  {
    iana_timezone: "America/Bogota",
    display_name: "(UTC-05:00) Bogota, Lima, Quito",
    abbreviation: "-05",
  },
  {
    iana_timezone: "America/Indianapolis",
    display_name: "(UTC-05:00) Indiana (East)",
    abbreviation: "EDT",
  },
  {
    iana_timezone: "America/Caracas",
    display_name: "(UTC-04:30) Caracas",
    abbreviation: "-04",
  },
  {
    iana_timezone: "America/Halifax",
    display_name: "(UTC-04:00) Atlantic Time (Canada)",
    abbreviation: "ADT",
  },
  {
    iana_timezone: "America/Cuiaba",
    display_name: "(UTC-04:00) Cuiaba",
    abbreviation: "-04",
  },
  {
    iana_timezone: "America/Santiago",
    display_name: "(UTC-04:00) Santiago",
    abbreviation: "-03",
  },
  {
    iana_timezone: "America/La_Paz",
    display_name: "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
    abbreviation: "-04",
  },
  {
    iana_timezone: "America/Asuncion",
    display_name: "(UTC-04:00) Asuncion",
    abbreviation: "-04",
  },
  {
    iana_timezone: "America/St_Johns",
    display_name: "(UTC-03:30) Newfoundland",
    abbreviation: "NDT",
  },
  {
    iana_timezone: "America/Sao_Paulo",
    display_name: "(UTC-03:00) Brasilia",
    abbreviation: "-03",
  },
  {
    iana_timezone: "America/Godthab",
    display_name: "(UTC-03:00) Greenland",
    abbreviation: "-01",
  },
  {
    iana_timezone: "America/Montevideo",
    display_name: "(UTC-03:00) Montevideo",
    abbreviation: "-03",
  },
  {
    iana_timezone: "America/Cayenne",
    display_name: "(UTC-03:00) Cayenne, Fortaleza",
    abbreviation: "-03",
  },
  {
    iana_timezone: "America/Buenos_Aires",
    display_name: "(UTC-03:00) Buenos Aires",
    abbreviation: "-03",
  },
  {
    iana_timezone: "Atlantic/Azores",
    display_name: "(UTC-01:00) Azores",
    abbreviation: "+00",
  },
  {
    iana_timezone: "Europe/London",
    display_name: "(UTC) Dublin, Edinburgh, Lisbon, London",
    abbreviation: "BST",
  },
  {
    iana_timezone: "Atlantic/Reykjavik",
    display_name: "(UTC) Monrovia, Reykjavik",
    abbreviation: "GMT",
  },
  {
    iana_timezone: "Africa/Casablanca",
    display_name: "(UTC) Casablanca",
    abbreviation: "+01",
  },
  {
    iana_timezone: "Europe/Budapest",
    display_name:
      "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    abbreviation: "CEST",
  },
  {
    iana_timezone: "Europe/Warsaw",
    display_name: "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
    abbreviation: "CEST",
  },
  {
    iana_timezone: "Europe/Paris",
    display_name: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
    abbreviation: "CEST",
  },
  {
    iana_timezone: "Africa/Lagos",
    display_name: "(UTC+01:00) West Central Africa",
    abbreviation: "WAT",
  },
  {
    iana_timezone: "Europe/Berlin",
    display_name:
      "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    abbreviation: "CEST",
  },
  {
    iana_timezone: "Africa/Windhoek",
    display_name: "(UTC+01:00) Windhoek",
    abbreviation: "CAT",
  },
  {
    iana_timezone: "Europe/Chisinau",
    display_name: "(UTC+02:00) Minsk",
    abbreviation: "EEST",
  },
  {
    iana_timezone: "Africa/Cairo",
    display_name: "(UTC+02:00) Cairo",
    abbreviation: "EEST",
  },
  {
    iana_timezone: "Europe/Kiev",
    display_name: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
    abbreviation: "EEST",
  },
  {
    iana_timezone: "Europe/Bucharest",
    display_name: "(UTC+02:00) Athens, Bucharest",
    abbreviation: "EEST",
  },
  {
    iana_timezone: "Asia/Jerusalem",
    display_name: "(UTC+02:00) Jerusalem",
    abbreviation: "IDT",
  },
  {
    iana_timezone: "Asia/Amman",
    display_name: "(UTC+02:00) Amman",
    abbreviation: "+03",
  },
  {
    iana_timezone: "Asia/Beirut",
    display_name: "(UTC+02:00) Beirut",
    abbreviation: "EEST",
  },
  {
    iana_timezone: "Africa/Johannesburg",
    display_name: "(UTC+02:00) Harare, Pretoria",
    abbreviation: "SAST",
  },
  {
    iana_timezone: "Asia/Damascus",
    display_name: "(UTC+02:00) Damascus",
    abbreviation: "+03",
  },
  {
    iana_timezone: "Europe/Istanbul",
    display_name: "(UTC+02:00) Istanbul",
    abbreviation: "+03",
  },
  {
    iana_timezone: "Asia/Riyadh",
    display_name: "(UTC+03:00) Kuwait, Riyadh",
    abbreviation: "+03",
  },
  {
    iana_timezone: "Asia/Baghdad",
    display_name: "(UTC+03:00) Baghdad",
    abbreviation: "+03",
  },
  {
    iana_timezone: "Africa/Nairobi",
    display_name: "(UTC+03:00) Nairobi",
    abbreviation: "EAT",
  },
  {
    iana_timezone: "Europe/Kaliningrad",
    display_name: "(UTC+03:00) Kaliningrad",
    abbreviation: "EET",
  },
  {
    iana_timezone: "Asia/Tehran",
    display_name: "(UTC+03:30) Tehran",
    abbreviation: "+0330",
  },
  {
    iana_timezone: "Europe/Moscow",
    display_name: "(UTC+04:00) Moscow, St. Petersburg, Volgograd",
    abbreviation: "MSK",
  },
  {
    iana_timezone: "Asia/Dubai",
    display_name: "(UTC+04:00) Abu Dhabi, Muscat",
    abbreviation: "+04",
  },
  {
    iana_timezone: "Asia/Baku",
    display_name: "(UTC+04:00) Baku",
    abbreviation: "+04",
  },
  {
    iana_timezone: "Asia/Yerevan",
    display_name: "(UTC+04:00) Yerevan",
    abbreviation: "+04",
  },
  {
    iana_timezone: "Asia/Tbilisi",
    display_name: "(UTC+04:00) Tbilisi",
    abbreviation: "+04",
  },
  {
    iana_timezone: "Indian/Mauritius",
    display_name: "(UTC+04:00) Port Louis",
    abbreviation: "+04",
  },
  {
    iana_timezone: "Asia/Kabul",
    display_name: "(UTC+04:30) Kabul",
    abbreviation: "+0430",
  },
  {
    iana_timezone: "Asia/Tashkent",
    display_name: "(UTC+05:00) Tashkent",
    abbreviation: "+05",
  },
  {
    iana_timezone: "Asia/Karachi",
    display_name: "(UTC+05:00) Islamabad, Karachi",
    abbreviation: "PKT",
  },
  {
    iana_timezone: "Asia/Calcutta",
    display_name: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    abbreviation: "IST",
  },
  {
    iana_timezone: "Asia/Colombo",
    display_name: "(UTC+05:30) Sri Jayawardenepura",
    abbreviation: "+0530",
  },
  {
    iana_timezone: "Asia/Katmandu",
    display_name: "(UTC+05:45) Kathmandu",
    abbreviation: "+0545",
  },
  {
    iana_timezone: "Asia/Yekaterinburg",
    display_name: "(UTC+06:00) Ekaterinburg",
    abbreviation: "+05",
  },
  {
    iana_timezone: "Asia/Bishkek",
    display_name: "(UTC+06:00) Astana",
    abbreviation: "+06",
  },
  {
    iana_timezone: "Asia/Dhaka",
    display_name: "(UTC+06:00) Dhaka",
    abbreviation: "+06",
  },
  {
    iana_timezone: "Asia/Rangoon",
    display_name: "(UTC+06:30) Yangon (Rangoon)",
    abbreviation: "+0630",
  },
  {
    iana_timezone: "Asia/Novosibirsk",
    display_name: "(UTC+07:00) Novosibirsk",
    abbreviation: "+07",
  },
  {
    iana_timezone: "Asia/Bangkok",
    display_name: "(UTC+07:00) Bangkok, Hanoi, Jakarta",
    abbreviation: "+07",
  },
  {
    iana_timezone: "Asia/Krasnoyarsk",
    display_name: "(UTC+08:00) Krasnoyarsk",
    abbreviation: "+07",
  },
  {
    iana_timezone: "Asia/Shanghai",
    display_name: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
    abbreviation: "CST",
  },
  {
    iana_timezone: "Asia/Singapore",
    display_name: "(UTC+08:00) Kuala Lumpur, Singapore",
    abbreviation: "+08",
  },
  {
    iana_timezone: "Asia/Taipei",
    display_name: "(UTC+08:00) Taipei",
    abbreviation: "CST",
  },
  {
    iana_timezone: "Australia/Perth",
    display_name: "(UTC+08:00) Perth",
    abbreviation: "AWST",
  },
  {
    iana_timezone: "Asia/Ulaanbaatar",
    display_name: "(UTC+08:00) Ulaanbaatar",
    abbreviation: "+08",
  },
  {
    iana_timezone: "Asia/Irkutsk",
    display_name: "(UTC+09:00) Irkutsk",
    abbreviation: "+08",
  },
  {
    iana_timezone: "Asia/Seoul",
    display_name: "(UTC+09:00) Seoul",
    abbreviation: "KST",
  },
  {
    iana_timezone: "Asia/Tokyo",
    display_name: "(UTC+09:00) Osaka, Sapporo, Tokyo",
    abbreviation: "JST",
  },
  {
    iana_timezone: "Australia/Darwin",
    display_name: "(UTC+09:30) Darwin",
    abbreviation: "ACST",
  },
  {
    iana_timezone: "Australia/Adelaide",
    display_name: "(UTC+09:30) Adelaide",
    abbreviation: "ACST",
  },
  {
    iana_timezone: "Asia/Yakutsk",
    display_name: "(UTC+10:00) Yakutsk",
    abbreviation: "+09",
  },
  {
    iana_timezone: "Australia/Sydney",
    display_name: "(UTC+10:00) Canberra, Melbourne, Sydney",
    abbreviation: "AEST",
  },
  {
    iana_timezone: "Australia/Brisbane",
    display_name: "(UTC+10:00) Brisbane",
    abbreviation: "AEST",
  },
  {
    iana_timezone: "Australia/Hobart",
    display_name: "(UTC+10:00) Hobart",
    abbreviation: "AEST",
  },
  {
    iana_timezone: "Pacific/Port_Moresby",
    display_name: "(UTC+10:00) Guam, Port Moresby",
    abbreviation: "+10",
  },
  {
    iana_timezone: "Asia/Vladivostok",
    display_name: "(UTC+11:00) Vladivostok",
    abbreviation: "+10",
  },
  {
    iana_timezone: "Pacific/Guadalcanal",
    display_name: "(UTC+11:00) Solomon Is., New Caledonia",
    abbreviation: "+11",
  },
  {
    iana_timezone: "Asia/Magadan",
    display_name: "(UTC+12:00) Magadan",
    abbreviation: "+11",
  },
  {
    iana_timezone: "Pacific/Fiji",
    display_name: "(UTC+12:00) Fiji",
    abbreviation: "+12",
  },
  {
    iana_timezone: "Pacific/Auckland",
    display_name: "(UTC+12:00) Auckland, Wellington",
    abbreviation: "NZST",
  },
  {
    iana_timezone: "Pacific/Tongatapu",
    display_name: "(UTC+13:00) Nuku'alofa",
    abbreviation: "+13",
  },
  {
    iana_timezone: "Pacific/Apia",
    display_name: "(UTC-11:00)Samoa",
    abbreviation: "+13",
  },
];
