import {
  LoaderFunction,
  Outlet,
  useLoaderData,
  useLocation,
  useNavigate,
  useSearchParams,
  Link as RouterLink,
} from "react-router-dom";
import * as api from "../../../services/api";
import { Box, Link, Tab, TabList, Tabs } from "@chakra-ui/react";
import { VianikoEvent } from "../../../types/events";
import { useOrganizationUser } from "../../../providers/CurrentOrganizationUserProvider";
import {
  eventAttendeesUrl,
  eventMessagesUrl,
  eventProgramUrl,
  eventShowUrl,
  eventTicketsUrl,
  homeUrl,
  organizationShowUrl,
} from "../../../services/routes/urlBuilder";
import { useCurrentEventUser } from "../../../providers/CurrentEventUserProvider";
import { EventThemeCard } from "../components/EventThemeCard";
import { Organization } from "../../../types/organization";
import { ManageEventMenuListItems } from "../ManageEventMenuListItems";
import { ExtendedUser, useAttendees } from "../hooks/useAttendees";
import { useEffect, useMemo, useState } from "react";
import { PageHeader } from "../../../components/PageHeader";
import { EventInviteFriendsModal } from "../components/EventInviteFriendsModal";
import { TicketTypeSelectionModal } from "../components/forms/TicketTypeSelectionModal";
import { EventActionButton } from "../components/EventActionButton";
import { useSubEvents } from "../hooks/useSubEvents";
import { useCurrentUser } from "../../../providers/CurrentUserProvider";
import { useEventTickets } from "../../../hooks/useEventTickets";
import {
  hasPermission,
  PERMISSION_MANAGE_EVENTS,
} from "../../../services/permissions";
import { Ticket } from "../../../types/ticket";

interface LoaderData {
  event: VianikoEvent;
  organization: Organization;
}

export const loader: LoaderFunction = async ({
  params: { eventId },
}): Promise<LoaderData> => {
  if (!eventId) throw new Error("Event ID is required");

  const { event, organization } = await api.events.fetchEvent(eventId);
  return { event, organization };
};

export interface EventShowOutletContext {
  showAttendees: boolean;
  showProgram: boolean;
  showTickets: boolean;
  showMessages: boolean;
  event: VianikoEvent;
  subEvents: VianikoEvent[];
  organization: Organization;
  tickets: Ticket[];
  attendees: ExtendedUser[];
  numAttendees: number;
  isAdmin: boolean;
  onBuyTickets: () => void;
  handleRefundTickets: () => void;
}

export const EventShowPage: React.FC = () => {
  const { event, organization } = useLoaderData() as LoaderData;
  const { subEvents } = useSubEvents(event.id);
  const { currentUser } = useCurrentUser();
  const { organizationUser } = useOrganizationUser(event.organization_id);
  const { currentEventUser } = useCurrentEventUser();
  const {
    attendees,
    refetch: refetchAttendees,
    numAttendees,
  } = useAttendees(event.id);
  const { tickets, eventTickets } = useEventTickets(event.id);
  const [isInviteFriendsModalOpen, setIsInviteFriendsModalOpen] =
    useState(false);
  const [isTicketTypeSelectionModalOpen, setIsTicketTypeSelectionModalOpen] =
    useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isInvite = !!searchParams.get("invite");
  const isTicketSelection = !!searchParams.get("ticket_selection");

  useEffect(() => {
    if (isInvite) {
      setIsInviteFriendsModalOpen(true);
    }
  }, [isInvite]);

  useEffect(() => {
    if (isTicketSelection && !!currentUser) {
      setIsTicketTypeSelectionModalOpen(true);
    }
  }, [isTicketSelection, currentUser]);

  const isAdmin = !!organizationUser?.is_owner || !!currentEventUser?.is_owner;

  const handleDelete = async () => {
    navigate(
      event.organization_id
        ? organizationShowUrl(event.organization_id)
        : homeUrl()
    );
  };

  const handleRefundTickets = async () => {
    await refetchAttendees();
  };

  const handleCloseInviteModal = () => {
    window.location.href = eventShowUrl(event.id);
  };

  const onBuyTickets = () => {
    setIsTicketTypeSelectionModalOpen(true);
  };

  const showAttendees = isAdmin || eventTickets.length > 0;
  const showProgram = isAdmin || subEvents.length > 0;
  const showTickets = (isAdmin || tickets.length > 0) && !event.parent_event_id;
  const showMessages = isAdmin;

  const tabIndex = useMemo(() => {
    if (pathname.includes("attendees")) {
      return 1;
    } else if (pathname.includes("program")) {
      const numPreviousTabs = showAttendees ? 2 : 1;
      return numPreviousTabs;
    } else if (pathname.includes("tickets")) {
      let numPreviousTabs = 1;
      if (showAttendees) numPreviousTabs++;
      if (showProgram) numPreviousTabs++;
      return numPreviousTabs;
    } else if (pathname.includes("messages")) {
      let numPreviousTabs = 1;
      if (showAttendees) numPreviousTabs++;
      if (showProgram) numPreviousTabs++;
      if (showTickets) numPreviousTabs++;
      return numPreviousTabs;
    } else {
      return 0;
    }
  }, [pathname, showAttendees, showProgram, showTickets]);

  return (
    <>
      <EventInviteFriendsModal
        event={event}
        isOpen={isInviteFriendsModalOpen}
        onClose={handleCloseInviteModal}
      />
      <TicketTypeSelectionModal
        event={event}
        isOpen={isTicketTypeSelectionModalOpen}
        onClose={() => setIsTicketTypeSelectionModalOpen(false)}
      />

      <PageHeader
        menuListContent={
          isAdmin ||
          hasPermission(
            organizationUser?.permissions,
            PERMISSION_MANAGE_EVENTS
          ) ? (
            <ManageEventMenuListItems
              eventId={event.id}
              recurringEventId={event.recurring_event_id}
              onDelete={handleDelete}
            />
          ) : null
        }
      />

      <Box marginBottom={1}>
        <EventThemeCard event={event} organization={organization} />
      </Box>

      <EventActionButton
        event={event}
        onGetTicketsClick={() => setIsTicketTypeSelectionModalOpen(true)}
        onInviteFriendsClick={() => setIsInviteFriendsModalOpen(true)}
        isAdmin={isAdmin}
      />

      <Tabs marginY={2} index={tabIndex}>
        <TabList>
          <Link as={RouterLink} variant="unstyled" to={eventShowUrl(event.id)}>
            <Tab>Info</Tab>
          </Link>

          {showAttendees && (
            <Link
              as={RouterLink}
              variant="unstyled"
              to={eventAttendeesUrl(event.id)}
            >
              <Tab>{`${numAttendees} ${
                numAttendees === 1 ? "attendee" : "attendees"
              }`}</Tab>
            </Link>
          )}
          {showProgram && (
            <Link
              as={RouterLink}
              variant="unstyled"
              to={eventProgramUrl(event.id)}
            >
              <Tab>Program</Tab>
            </Link>
          )}

          {showTickets && (
            <Link
              as={RouterLink}
              variant="unstyled"
              to={eventTicketsUrl(event.id)}
            >
              <Tab>Tickets</Tab>
            </Link>
          )}

          {showMessages && (
            <Link
              as={RouterLink}
              variant="unstyled"
              to={eventMessagesUrl(event.id)}
            >
              <Tab>Messages</Tab>
            </Link>
          )}
        </TabList>
      </Tabs>

      <Outlet
        context={{
          showAttendees,
          showProgram,
          showTickets,
          showMessages,
          event,
          subEvents,
          attendees,
          numAttendees,
          handleRefundTickets,
          organization,
          tickets,
          isAdmin,
          onBuyTickets,
        }}
      />
    </>
  );
};
