import { TicketType } from "../../types/ticket_types";
import axiosInstance from "./axiosInstance";

export const fetchTicketType = async (ticketTypeId: string) => {
  const response = await axiosInstance.get(`/ticket_types/${ticketTypeId}`);
  return response.data;
};

export const fetchTicketTypeByCode = async (eventId: string, code: string) => {
  const response = await axiosInstance.get(`/events/${eventId}/codes/${code}`);
  return response.data;
};

export const fetchEventTicketTypesAdmin = async (eventId: string) => {
  const response = await axiosInstance.get(`/events/${eventId}/ticket_types`);
  return response.data;
};

export const fetchTicketTypeEventEnrollments = async (ticketTypeId: string) => {
  const response = await axiosInstance.get(
    `/ticket_types/${ticketTypeId}/event_enrollments`
  );
  return response.data;
};

export const createTicketType = async (ticketType: TicketType) => {
  const response = await axiosInstance.post(`/ticket_types`, ticketType);
  return response.data;
};

export const updateTicketType = async (ticketType: TicketType) => {
  const response = await axiosInstance.put(
    `/ticket_types/${ticketType.id}`,
    ticketType
  );
  return response.data;
};

export const deleteTicketType = async (ticketTypeId: string) => {
  const response = await axiosInstance.delete(`/ticket_types/${ticketTypeId}`);
  return response.data;
};
