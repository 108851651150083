import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { User } from "firebase/auth";
import { useFirebaseApp } from "./FirebaseAppProvider";
import {
  setupAuthInterceptor,
  setupAuthRefreshInterceptor,
} from "../services/api/axiosInstance";

interface AuthContextData {
  user: User | null;
  initialized: boolean;
  refreshToken: () => void;
  refreshing: boolean;
  signOut: () => void;
}

export const AuthContext = createContext<AuthContextData>({
  user: null,
  initialized: false,
  refreshToken: () => {},
  refreshing: false,
  signOut: () => {},
});

export const useAuth = () => {
  return useContext(AuthContext);
};

interface AuthProviderProps {
  children: React.ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<User | null>(null);
  const [initialized, setInitialized] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const firebaseApp = useFirebaseApp();

  const refreshToken = useCallback(async () => {
    if (refreshing || !user) return;
    setRefreshing(true);
    const token = await user.getIdToken(true);
    setRefreshing(false);
    setupAuthInterceptor(token);
  }, [user, refreshing]);

  useEffect(() => {
    if (!firebaseApp) return;

    const init = async () => {
      await firebaseApp.auth.authStateReady;
      const u = firebaseApp.auth.currentUser;
      const token = u ? await u.getIdToken() : null;
      if (token) {
        setupAuthInterceptor(token);
      }
      setInitialized(true);
      setUser(u);
    };

    const unsubscribe = firebaseApp.auth.onAuthStateChanged(async (u) => {
      const token = u ? await u.getIdToken() : null;
      if (token) {
        setupAuthInterceptor(token);
      }
      setUser(u);
    });

    init();

    return () => unsubscribe();
  }, [firebaseApp]);

  useEffect(() => {
    if (!user) return;
    setupAuthRefreshInterceptor(refreshToken);
  }, [user, refreshToken]);

  const value = {
    user,
    initialized,
    refreshToken,
    refreshing,
    signOut: async () => {
      await firebaseApp?.auth.signOut();
      window.location.href = "/";
    },
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
