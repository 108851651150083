import { useCallback, useEffect, useState } from "react";
import { VianikoEvent } from "../../../types/events";
import { fetchSubEvents } from "../../../services/api/events";

interface UseSubEvents {
  subEvents: VianikoEvent[];
  refetch: () => void;
}

export const useSubEvents = (eventId: string | undefined): UseSubEvents => {
  const [subEvents, setSubEvents] = useState<VianikoEvent[]>([]);

  const fetch = useCallback(async () => {
    if (!eventId) return;

    const subEvents = await fetchSubEvents(eventId);
    setSubEvents(subEvents);
  }, [eventId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { subEvents, refetch: fetch };
};
