import { loadStripe, Stripe } from "@stripe/stripe-js";
import { CURRENCY_CODE_TO_CURRENCY } from "./currencies";
import { PaymentType } from "../types/ticket_types";

export const priceForTicketType = (
  paymentType: PaymentType,
  priceInCents: number,
  currencyIsoCode: string,
  precision?: number
): string => {
  if (paymentType === "free") {
    return "Free";
  } else {
    return priceInDollars(currencyIsoCode, priceInCents, precision);
  }
};

export const priceInDollars = (
  currencyIsoCode: string,
  priceInCents?: number,
  precision?: number
): string => {
  if (priceInCents === undefined) return "";

  return `${CURRENCY_CODE_TO_CURRENCY[currencyIsoCode]?.symbol}${(
    priceInCents / 100
  ).toFixed(precision === undefined ? 2 : precision)}`;
};

export const initializeStripeAccount = async (
  connectedAccountId: string
): Promise<Stripe | null> => {
  if (!process.env.REACT_APP_STRIPE_PUB_KEY) {
    throw new Error("REACT_APP_STRIPE_PUB_KEY is required");
  }

  return await loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY, {
    stripeAccount: connectedAccountId,
  });
};
