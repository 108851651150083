import { useForm } from "react-hook-form";
import { OrganizationUserSelectInput } from "../../../components/OrganizationUserSelectInput";
import { Organization } from "../../../types/organization";
import { Button, HStack } from "@chakra-ui/react";

interface AddUserProps {
  organization: Organization;
  onSelect: (value: string) => void;
}

export const AddUser: React.FC<AddUserProps> = ({ organization, onSelect }) => {
  const { register, setValue, getValues } = useForm<{
    userId: string;
  }>({ mode: "onBlur" });

  const handleSelect = (value: string) => {
    setValue("userId", value);
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSelect(getValues().userId);
  };

  return (
    <form onSubmit={handleSubmit}>
      <HStack>
        <OrganizationUserSelectInput
          name="userId"
          label=""
          organizationId={organization.id}
          register={register}
          setValue={setValue}
          registerOptions={{ required: true }}
          placeholder="Select member"
          onSelect={handleSelect}
        />

        <Button type="submit" variant="primary" size="lg">
          Add
        </Button>
      </HStack>
    </form>
  );
};
