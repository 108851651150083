import {
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Spacer,
} from "@chakra-ui/react";
import React from "react";
import { BiDotsHorizontalRounded } from "react-icons/bi";

interface PageHeaderProps {
  menuListContent?: React.ReactNode;
}

export const PageHeader: React.FC<PageHeaderProps> = ({ menuListContent }) => {
  return (
    <HStack height={12}>
      <Spacer />
      {menuListContent && (
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<Icon as={BiDotsHorizontalRounded} />}
            variant="ghost"
          />
          <MenuList>{menuListContent}</MenuList>
        </Menu>
      )}
    </HStack>
  );
};
