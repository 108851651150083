import { Button, Checkbox, HStack, VStack } from "@chakra-ui/react";
import {
  ALL_PERMISSIONS,
  hasPermission,
  Permission,
} from "../../../services/permissions";
import { OrganizationUser } from "../../../types/organization_users";
import { useState } from "react";
import { patchOrganizationUserPermissions } from "../../../services/api/organizations";
import { Organization } from "../../../types/organization";

interface EditPermissionsProps {
  organization?: Organization;
  organizationUser: OrganizationUser;
  onSubmit: () => void;
  onCancel: () => void;
}

export const EditPermissions: React.FC<EditPermissionsProps> = ({
  organization,
  organizationUser,
  onSubmit,
  onCancel,
}) => {
  const [permissions, setPermissions] = useState<bigint>(
    BigInt(organizationUser.permissions ?? 0)
  );

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!organization) return;

    await patchOrganizationUserPermissions(
      organization.id,
      organizationUser.user_id,
      permissions.toString()
    );

    onSubmit();
  };

  const handleChange = (permission: Permission) => () => {
    setPermissions((prev) => {
      return hasPermission(permissions, permission.value)
        ? BigInt(prev) ^ BigInt(permission.value)
        : BigInt(prev) | BigInt(permission.value);
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <VStack align="start">
        {ALL_PERMISSIONS.map((permission) => (
          <Checkbox
            key={permission.value}
            value={permission.value}
            isChecked={hasPermission(permissions, permission.value)}
            onChange={handleChange(permission)}
          >
            {permission.label}
          </Checkbox>
        ))}
      </VStack>

      <HStack marginTop={4}>
        <Button onClick={onCancel} size="lg" variant="ghost">
          Cancel
        </Button>
        <Button type="submit" variant="primary" size="lg" width="100%">
          Save
        </Button>
      </HStack>
    </form>
  );
};
