import { Outlet, useParams } from "react-router-dom";
import {
  TicketsCheckin,
  useTicketsCheckin,
} from "../../../hooks/useTicketsCheckin";
import { TicketStatus } from "../../../types/ticket";
import { patchTicket } from "../../../services/api/tickets";

export interface EventCheckinContext {
  checkinByTicketId: Record<string, TicketsCheckin>;
  onChangeCheckin: (ticketId: string, status: TicketStatus) => void;
}

export const EventCheckInParent: React.FC = () => {
  const { eventId } = useParams();

  const { checkinByTicketId, updateStatus } = useTicketsCheckin(eventId);

  const onChangeCheckin = async (ticketId: string, status: TicketStatus) => {
    const ticket = await patchTicket(ticketId, {
      status:
        checkinByTicketId[ticketId]?.status === "checked_in"
          ? "active"
          : "checked_in",
    });
    updateStatus(ticketId, ticket.status);
  };

  if (!eventId) return null;

  return <Outlet context={{ checkinByTicketId, onChangeCheckin }} />;
};
