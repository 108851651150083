import {
  Badge,
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { VianikoEvent } from "../../../types/events";
import { TiTicket } from "react-icons/ti";
import { TicketTypesSection } from "./forms/TicketTypesSection";
import { ButtonLink } from "../../../components/ButtonLink";
import {
  adminManageOrganizationPayoutUrl,
  ticketTypeEditUrl,
  ticketTypeNewUrl,
} from "../../../services/routes/urlBuilder";
import { Panel } from "../../../components/Panel";
import { useNavigate } from "react-router-dom";
import { TicketType } from "../../../types/ticket_types";
import { eventLevelTicketsUnvailable } from "../services";
import { Ticket } from "../../../types/ticket";
import { useTicketTypesAdmin } from "../../../hooks/useTicketTypesAdmin";
import { Organization } from "../../../types/organization";
import { QrCode } from "../checkin/QrCode";

interface EventTicketsProps {
  event: VianikoEvent;
  organization: Organization;
  tickets: Ticket[];
  isAdmin: boolean;
  onBuyTickets: () => void;
}

export const EventTickets: React.FC<EventTicketsProps> = ({
  event,
  organization,
  tickets,
  isAdmin,
  onBuyTickets,
}) => {
  const navigate = useNavigate();

  const { ticketTypes } = useTicketTypesAdmin(event.id);

  const handleEditTicketType = (ticketType: TicketType) => {
    navigate(ticketTypeEditUrl(event.id, ticketType.id));
  };

  const ticketsByType = tickets.reduce((acc, ticket) => {
    const ticketType = ticket.ticket_type_id;
    if (!acc[ticketType]) {
      acc[ticketType] = [];
    }
    acc[ticketType].push(ticket);
    return acc;
  }, {} as Record<string, Ticket[]>);

  return (
    <Panel>
      <VStack gap={4}>
        {isAdmin && (
          <Box width="100%">
            <HStack>
              <Heading size="md" width="100%">
                Event tickets
              </Heading>
              <Spacer />
              {!!event.organization_id && !organization.stripe_account_id && (
                <>
                  <Badge colorScheme="red">Payments disabled</Badge>
                  <ButtonLink
                    to={adminManageOrganizationPayoutUrl(organization.id)}
                    buttonProps={{
                      variant: "ghost",
                      size: "sm",
                    }}
                  >
                    Setup payments
                  </ButtonLink>
                </>
              )}
            </HStack>

            <ButtonLink
              to={ticketTypeNewUrl(event.id)}
              buttonProps={{
                variant: "primary",
                size: "lg",
                width: "100%",
              }}
              width="100%"
              marginY={2}
            >
              Add a ticket
            </ButtonLink>

            <TicketTypesSection
              ticketTypes={ticketTypes}
              currencyIsoCode={event.currency_iso_code}
              onEdit={handleEditTicketType}
              isAdmin={isAdmin}
            />
          </Box>
        )}

        {(tickets.length > 0 || isAdmin) && (
          <Box width="100%">
            <Heading size="md">Your tickets</Heading>

            {!eventLevelTicketsUnvailable(event) && (
              <Button
                variant="primary"
                size="lg"
                width="100%"
                marginY={2}
                onClick={onBuyTickets}
              >
                Buy more tickets
              </Button>
            )}

            <VStack>
              {Object.entries(ticketsByType).map(([ticketTypeId, tickets]) => {
                return (
                  <Box key={ticketTypeId} width="100%">
                    {tickets.map((ticket, index) => (
                      <Box key={ticket.id} width="100%">
                        <HStack>
                          <Icon as={TiTicket} />
                          <Text size="md">
                            {ticket.name} - {index + 1} of {tickets.length}
                          </Text>
                        </HStack>

                        <QrCode value={ticket.id} />
                      </Box>
                    ))}
                  </Box>
                );
              })}
            </VStack>
          </Box>
        )}
      </VStack>
    </Panel>
  );
};
