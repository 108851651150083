import React, { useCallback, useEffect, useState } from "react";
import { Panel } from "../../../components/Panel";
import { OrganizationUser } from "../../../types/organization_users";
import {
  fetchAdminOrganizationUsers,
  patchOrganizationUserPermissions,
} from "../../../services/api/organizations";
import { useCurrentOrganization } from "../../../providers/CurrentOrganizationProvider";
import { useCurrentUser } from "../../../providers/CurrentUserProvider";
import {
  Box,
  Heading,
  HStack,
  IconButton,
  Spacer,
  Tag,
  Text,
  VStack,
} from "@chakra-ui/react";
import { BiPencil } from "react-icons/bi";
import { ALL_PERMISSIONS, hasPermission } from "../../../services/permissions";
import { EditPermissions } from "./EditPermissions";
import { AddUser } from "./AddUser";
import { UserAvatar } from "../../../components/UserAvatar";
import { TEXT_SECONDARY_COLOR } from "../../../services/theme/colors";
import { VianikoUser } from "../../../types/users";

export const ManageOrganizationUsers: React.FC = () => {
  const [editingId, setEditingId] = useState<string | null>(null);

  const { organization } = useCurrentOrganization();
  const { currentUser } = useCurrentUser();
  const [organizationUsers, setOrganizationUsers] = React.useState<
    (VianikoUser & OrganizationUser)[]
  >([]);

  const fetchOrganizationUsers = useCallback(async () => {
    if (!organization || !currentUser) {
      return;
    }

    const organizationUsers = await fetchAdminOrganizationUsers(
      organization.id
    );

    setOrganizationUsers(organizationUsers);
  }, [organization, currentUser]);

  useEffect(() => {
    const init = async () => {
      fetchOrganizationUsers();
    };

    init();
  }, [fetchOrganizationUsers]);

  const handleEdit = (organizationUserId: string) => () => {
    setEditingId(organizationUserId);
  };

  const handleSubmitPermissions = () => {
    setEditingId(null);
    fetchOrganizationUsers();
  };

  const handleCancelPermissions = () => {
    setEditingId(null);
  };

  const handleSelectUser = async (userId: string) => {
    if (!organization) return;
    const adminOrganizationUser = organizationUsers.find(
      (organizationUser) => organizationUser.user_id === userId
    );

    if (!adminOrganizationUser) {
      await patchOrganizationUserPermissions(organization.id, userId, "0");
      await fetchOrganizationUsers();
    }
  };

  if (!organization) return null;

  return (
    <Panel>
      <Heading size="md" marginBottom={2}>
        Manage admins
      </Heading>
      <AddUser organization={organization} onSelect={handleSelectUser} />

      {organizationUsers.map((organizationUser) => (
        <Box key={organizationUser.id}>
          <HStack marginY={4}>
            <UserAvatar user={organizationUser} />
            <Text>
              {organizationUser.first_name} {organizationUser.last_name}
            </Text>
            <Spacer />

            {organizationUser.is_owner ? (
              <Text color={TEXT_SECONDARY_COLOR}>Owner</Text>
            ) : (
              <IconButton
                aria-label="Edit"
                icon={<BiPencil />}
                variant="primary"
                onClick={handleEdit(organizationUser.id)}
              />
            )}
          </HStack>

          {organizationUser.id === editingId ? (
            <EditPermissions
              organization={organization}
              organizationUser={organizationUser}
              onSubmit={handleSubmitPermissions}
              onCancel={handleCancelPermissions}
            />
          ) : (
            <VStack align="start" gap={1}>
              {ALL_PERMISSIONS.map(
                (permission) =>
                  hasPermission(
                    organizationUser.permissions,
                    permission.value
                  ) && <Tag key={permission.value}>{permission.label}</Tag>
              )}
            </VStack>
          )}
        </Box>
      ))}
    </Panel>
  );
};
