import { InputProps } from "@chakra-ui/react";
import { ReactNode } from "react";
import { FieldError, RegisterOptions, UseFormRegister } from "react-hook-form";
import { StyledInput } from "./StyledInput";

interface StyledRegisteredInputProps {
  name: string;
  label?: string;
  register: UseFormRegister<any>;
  error?: FieldError;
  type?: string;
  options?: RegisterOptions;
  inputProps?: InputProps;
  leftElement?: ReactNode;
}

export const StyledRegisteredInput: React.FC<StyledRegisteredInputProps> = ({
  name,
  label,
  register,
  error,
  type,
  options,
  leftElement,
  inputProps,
}) => {
  const isRequired = !!options?.required;

  return (
    <>
      <StyledInput
        name={name}
        label={label}
        error={error}
        type={type}
        isRequired={isRequired}
        inputProps={{
          ...register(name, options),
          ...inputProps,
          type,
        }}
        leftElement={leftElement}
      />
    </>
  );
};
