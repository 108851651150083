import { Flex, FlexProps, Icon, LinkBox, LinkOverlay } from "@chakra-ui/react";
import { ReactNode } from "react";
import { IconType } from "react-icons";
import {
  Link as RouterLink,
  useMatch,
  useResolvedPath,
} from "react-router-dom";
import { GHOST_HOVER_COLOR } from "../../../../services/theme/colors";

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: ReactNode;
  to: string;
}

export const NavItem = ({ icon, children, to, ...rest }: NavItemProps) => {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <LinkBox style={{ textDecoration: "none" }}>
      <Flex
        align="center"
        p={2}
        cursor="pointer"
        _hover={{
          bg: GHOST_HOVER_COLOR,
        }}
        backgroundColor={isActive ? GHOST_HOVER_COLOR : "transparent"}
        borderRadius={4}
        {...rest}
      >
        {icon && <Icon mr="4" fontSize="lg" as={icon} />}
        <LinkOverlay as={RouterLink} to={to}>
          {children}
        </LinkOverlay>
      </Flex>
    </LinkBox>
  );
};
